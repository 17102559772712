import { signIn, signOut, useSession } from 'next-auth/react';
import {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ISessionIfToken } from '../../pages/api/auth/[...nextauth]';
import { getBackendUrl } from '../../util/auth';
import { deleteCookie, getCookie, setCookie } from '../../util/cookies';

interface IAuth {
  login: any;
  logout: any;
  session: any;
  user: any;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setCheckout: (plans: any) => void;
  removeCheckout: (plans: any) => void;
}

interface ICompany {
  id: string;
  name: string;
  email: string;
  document: string;
  phone_number: string;
  address: {
    address: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
  };
}

export interface IUser {
  id?: string;
  name?: string;
  email?: string;
  created_at?: string;
  companies?: ICompany;
  checkout: any;
}

const AuthContext = createContext<IAuth>({} as IAuth);

export const AuthProvider: FunctionComponent<any> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>();
  const [loading, setLoading] = useState(false);
  const session = useSession();
  const login = signIn;
  const logout = signOut;

  const setCheckout = useCallback(
    plan => {
      const checkout = getCookie('carrinho') as any;
      if (checkout) {
        checkout.plans = plan;
        checkout.total = plan.value;
      }
      setCookie(
        'carrinho',
        JSON.stringify({
          plans: plan,
          total: plan.value,
        }),
        { expires: 7 },
      );
      if (user) {
        setUser({
          ...user,
          checkout: {
            plans: plan,
            total: plan.value,
          },
        });
      } else {
        setUser({
          checkout: {
            plans: plan,
            total: plan.value,
          },
        });
      }
    },
    [user],
  );

  const removeCheckout = useCallback(() => {
    deleteCookie('carrinho');
    if (user) {
      setUser({ ...user, checkout: null });
    } else {
      setUser({ checkout: null });
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    if (session.status === 'authenticated') {
      const plans = getCookie('carrinho');
      fetch(`${getBackendUrl()}/auth/users/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            (session.data as ISessionIfToken)?.accessToken
          }`,
        },
      })
        .then(async res => res.json())
        .then(response => {
          const { name, category_company_level_user, email, created_at, id } =
            response;
          const company = category_company_level_user.find(
            company => company.company_id && company.category_id === 1,
          );
          setUser({
            id,
            name,
            email,
            created_at,
            companies: company
              ? {
                  ...company.company,
                  address:
                    company.company.addresses.find(e => e.billing_address) ||
                    company.company.addresses[0],
                }
              : null,
            checkout: plans,
          });
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [session]);

  const contextValues = useMemo(
    () => ({
      login,
      logout,
      session,
      user,
      loading,
      setLoading,
      setCheckout,
      removeCheckout,
    }),
    [login, logout, session, user, loading, setCheckout, removeCheckout],
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
