/* eslint-disable react/no-unescaped-entities */
/* eslint-disable require-jsdoc */
import Document, { Head, Html, Main, NextScript } from 'next/document';
import dynamic from 'next/dynamic';

const Nobody = dynamic(() => import('../components/Nobody'));

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID || 'G-QS3C5T6DRW';
class MyDocument extends Document {
  render() {
    return (
      <Html lang={'pt-BR'}>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="keywords"
            content="devyx, lots, leandro, lucas, tecnologia, tech, telecom, zoug, buildr, builder, trader, negociação de dívidas, plataforma para envio de sms, sms, whatsapp, mensagem, wholesale, isp, provedor de telecomunicações, telecomunicação, telecom, telco, brasil"
          />
          <meta
            name="description"
            content="Know-how de mais uma década em solucões de telecomunicações com a agilidade de uma startup."
          />
          <meta
            name="facebook-domain-verification"
            content="ukqwd8ndm1efh8ielyo72rgvw9jufp"
          />
          <link rel="shortcut icon" type="image/png" href="favicon.png" />
          <meta name="robots" content="all" />
        </Head>
        <body>
          <Nobody />
          <Main />
          <NextScript />
        </body>
      </Html>
    );
  }
}

export default MyDocument;
